@import 'theme/ValgroupColors';

.DropdownActions {
  .DropdownActionsButton {
    &::after {
      display: none;
    }

    width: 33px;
    height: 33px;
    padding: 0;
    border-radius: 33px;
    background-color: $ValGray300;
    color: $ValBlack800;
  }

  .dropdown-menu.show {
    z-index: 9999 !important;
  }
}