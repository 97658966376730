@import 'theme/ValgroupColors';

.CharacterCounter {
  > span {
    color: $ValGray800;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
  }

  .invalid {
    color: $BurntSienna;
  }
}