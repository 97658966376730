// VALCOLORS
$ValWhite: #FFF;
$ValBlack: #000;
$ValBlack800: #000000CC;
$ValBlack500: #00000080;
$ValGreen: #48925C;
$ValBlue: #1D284A;
$ValBlue900: #1D284AE5;
$ValGray300: #B2B2B24D;
$ValGray800: #B2B2B2CC;
$OrangeYellowCrayola: #E9C46A;
$BurntSienna: #E86F51;

// BOOTSTRAP OVERRIDES
$primary: #005BBF;
// $secondary:     $gray-600 !default;
$success: $ValGreen;
// $info:          $cyan !default;
$warning: $OrangeYellowCrayola;
$danger: $BurntSienna;

$body-bg: $ValWhite;
$border-color-translucent: $ValGray300;