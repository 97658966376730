.pagination {
  z-index: 0;
}

.pagination .page-item:first-of-type {
  border-radius: 0.5rem 0px 0px 0.5rem;
}

.pagination .page-item:last-of-type {
  border-radius: 0 0.5rem 0.5rem 0;
}

.pagination a:focus,
.pagination a:hover {
  box-shadow: none;
  color: unset;
}

.page-item .page-link {
  color: #676767;
  width: 1.9rem;
  height: 2.5rem;
  border: solid #e9e9e9 1px !important;
  border-left: none;
  line-height: 2.5rem;
  text-align: center;
}

.page-item.active .page-link {
  color: white !important;
  background: #1d284a !important;
}

.page-link {
  font-size: 0.8rem !important;
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
  line-height: 0;
}
