@import 'ValgroupColors';
@import 'react-toastify/dist/ReactToastify.css';

$ValWhite: #FFF;
$ValBlack: #000;
$ValBlack800: #000000CC;
$ValBlack500: #00000080;
$ValGreen: #48925C;
$ValBlue: #1D284A;
$ValBlue900: #1D284AE5;
$ValGray300: #B2B2B24D;
$ValGray800: #B2B2B2CC;
$OrangeYellowCrayola: #E9C46A;
$BurntSienna: #E86F51;

:root {
  --toastify-color-light: #FFF;
  --toastify-color-dark: #000000CC;
  --toastify-color-info: #005BBF;
  --toastify-color-success: #48925C;
  --toastify-color-warning: #E9C46A;
  --toastify-color-error: #E86F51;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-text-color-light: #00000080;
  --toastify-text-color-dark: #FFF;

  --toastify-text-color-info: #FFF;
  --toastify-text-color-success: #FFF;
  --toastify-text-color-warning: #FFF;
  --toastify-text-color-error: #FFF;

  --toastify-color-progress-light: #48925C;
  --toastify-color-progress-dark: #48925C;
}