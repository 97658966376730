@import 'theme/ValgroupColors';

.ListTileColumn {
  font-size: 10px;
  font-weight: 600;

  h6 {
    font-size: 10px;
    font-weight: 600;
    color: $ValGray800;
  }
}