@import 'theme/ValgroupColors';

.DialogModal {
  .modal-title {
    color: $ValBlue;
  }

  .modal-body {
    text-align: center;
    padding: 30px 30px 0 30px;
    font-weight: 500;
    color: $ValBlue;
  }

  .modal-footer {
    padding: 30px;
  }

  .modal-actions-wrapper {
    display: flex;
    justify-content: space-around;
  }

  .btn {
    min-width: 104px;
  }
}