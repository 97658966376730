@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import 'theme/ValgroupColors';

@import 'node_modules/bootstrap/scss/bootstrap';    
@import 'theme/ValgroupUtilities';


* {
  font-family: 'Poppins', var(--bs-body-font-family);
}

// TODO: passar o tamanho das fontes para rem e aumentar a fonte em 2/4px no body quando a tela for de Desktop

// HEADERS
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-weight: 700;
}

h2, .h2, h1, .h1 {
  color: $ValBlue;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3 {
  color: $ValBlue900;
}

// BUTTON
.btn {
  border-radius: 6px;
  padding: 4px 11.5px;
  font-size: 12px;
  font-weight: 600;

  &[class*="btn-outline-"] {
    border: solid 2px;
  }

  &.btn-success, &.btn-success:hover, &.btn-success:active {
    color: white;
  }
  &.btn-outline-success:hover, &.btn-outline-success:active {
    color: white;
    border-color: $ValGreen;
  }

  &.btn-primary, &.btn-primary:hover, &.btn-primary:active {
    color: white;
  }
  &.btn-outline-primary:hover, &.btn-outline-primary:active {
    color: white;
    border-color: $primary;
  }

  &.btn-warning, &.btn-warning:hover, &.btn-warning:active {
    color: white;
  }
  &.btn-outline-warning:hover, &.btn-outline-warning:active {
    color: white;
    border-color: $OrangeYellowCrayola;
  }

  &.btn-danger, &.btn-danger:hover, &.btn-danger:active {
    color: white;
  }
  &.btn-outline-danger:hover, &.btn-outline-danger:active {
    color: white;
    border-color: $BurntSienna;
  }

  &.btn-lg {
    font-size: 16px;
  }
}

// FORM
.form-control, .form-select {
  border-radius: 4px;
  font-size: 12px;
  padding: 10px 0.75rem;

  &:focus {
    border-color: $ValGray800;
    box-shadow: 0 0 0 0.05rem $ValGray800;
  }

  &::placeholder {
    color: $ValGray800;
  }
}

.form-label, .invalid-feedback {
  font-family: Arial, Helvetica, sans-serif;
}

.form-label {
  font-size: 14px;
  color: $ValBlack500;
}

.invalid-feedback {
  font-size: 10px;
}

// MODAL
.modal-header, .modal-footer {
  border: none;
}

// FORMTABS
.FormTabs {
  > .card-body {
    padding: 20px 30px 30px 30px;
  }
  
  .card-footer {
    padding: 0 30px 30px 30px;
  }

  .card-header {
    background-color: $ValGray300;
    border-bottom: none;
    z-index: 1 !important;

    .nav-link {
      font-size: 10px;
      font-weight: 600;
      color: $ValBlue;
      margin-bottom: 16px;
      border-radius: 8px;
    }

    &::after {
      content: ".";
      color: $ValWhite;
      background-color: $ValWhite;
      display: block;
      width: 100%;
      height: 20;
      position: absolute;
      left: 0;
      top: 50px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .card-body {
    border-radius: 8px;
  }
}

// MISC
.navbar.bg-light {
  background-color: white !important;
}

.badge {
  font-size: 10px;
  font-weight: 600;

  &.bg-warning {
    color: $ValBlack;
  }

  &.lg {
    font-size: 14px;
  }

  &.codigo-projeto {
    background-color: $ValGray300 !important;
    color: $ValBlack500;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button,
input[type=number] { 
    -webkit-appearance: none; 
    -moz-appearance: textfield;
    margin: 0; 
}